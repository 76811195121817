<template>
  <div id="header">
    <div id="site-top"><br /></div>
    <nav class="fixed w-full top-0 z-20 bg-white">
      <div class="main-nav py-4 shadow-lg px-8 md:px-0">
        <div
          class="
            container
            mx-auto
            flex
            md:flex-col
            lg:flex-row
            gap-4
            justify-between
            items-center
          "
        >
          <router-link to="/">
            <img src="../assets/main_logo.svg" alt="" />
          </router-link>
          <div class="hidden md:flex gap-8 items-center text-zinc-700">
            <a class="menu-item" href="#demo-section">Demo</a>
            <a class="menu-item" href="#prices">Precios</a>
            <a class="menu-item" href="#solutions">¿Qué solucionamos?</a>
            <a
              class="menu-item border-b border-gray-400"
              href="https://app.botcity.com.do/"
              >Iniciar sesión</a
            >
            <div class="shrink-0">
              <a
                class="site-button btn"
                href="https://share.hsforms.com/1gOLON_nYSZ6zMbNxPoduog4k2r6"
                target="_blank"
                >Empieza Ahora</a
              >
            </div>
          </div>

          <button class="md:hidden" id="mobileMenu">
            <img src="" alt="" />
            <img src="../assets/menu-icon.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="hidden" id="mainNav">
        <div class="mobile-menu-wrapper" id="mobileOption">
          <a class="border-b border-gray-300 w-full" href="#demo-section"
            >Demo</a
          >

          <a class="border-b border-gray-300 w-full" href="#prices">Precios</a>

          <a class="border-b border-gray-300 w-full" href="#solutions"
            >¿Qué solucionamos?
          </a>

          <a class="w-full" href="https://app.botcity.com.do/">
            Iniciar sesión
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
};
</script>