<template>
  <div id="footer">
    <div class="footer px-8 md:px-0 py-16">
      <div
        class="
          container
          mx-auto
          flex
          justify-between
          flex-col
          md:flex-row
          gap-8
        "
      >
        <div class="flex flex-col justify-between gap-4">
          <div>
            <img class="w-40" src="../assets/botcity-logo-white.svg" alt="" />
            <p>Bots y humanos trabajando juntos</p>
          </div>

          <div class="flex gap-4">
            <a href="https://www.instagram.com/botcityrd/" target="_blank"
              ><img class="social-icon" src="../assets/instagram.svg" alt=""
            /></a>
            <a href="https://twitter.com/botcityrd" target="_blank"
              ><img class="social-icon" src="../assets/twitter.svg" alt=""
            /></a>
            <a
              href="https://www.facebook.com/Botcity-108432908175710"
              target="_blank"
              ><img class="social-icon" src="../assets/facebook.svg" alt=""
            /></a>
            <a
              href="https://www.linkedin.com/company/botcity-dominicana"
              target="_blank"
              ><img class="social-icon" src="../assets/linkedin.svg" alt=""
            /></a>
          </div>

          <div>
            <p>Backed up by:</p>
            <div class="flex flex-wrap gap-4">
              <img
                class="h-8 sm:h-10 pt-2 shrink-0"
                src="../assets/cree_logo.png"
                alt="Cree Banreservas"
              />
              <img
                class="h-8 sm:h-10 pt-2 shrink-0"
                src="../assets/enlaces-logo.png"
                alt="Enlaces"
              />
              <img
                class="h-8 sm:h-10 pt-2 shrink-0"
                src="../assets/boost-logo.png"
                alt="Boost"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-4 font-light justify-between">
          <a
            href="mailto:info@botcity.com.do"
            target="_blank"
            class="flex gap-2"
          >
            <img src="../assets/email.svg" alt="email" />
            <p>info@botcity.com.do</p>
          </a>
          <a href="tel:+8098207141" target="_blank" class="flex gap-2">
            <img class="" src="../assets/phone.svg" alt="phone" />
            <p>+1809 820 7141</p>
          </a>
          <a
            href="https://g.page/PyhexWork?share"
            target="_blank"
            class="flex gap-2"
          >
            <img class="" src="../assets/address.svg" alt="address" />
            <p>
              Pyhex Work - Av. John F. Kennedy No. 7<br />Santo Domingo, Rep.
              Dom.
            </p>
          </a>
        </div>

        <div class="flex flex-col gap-4 font-light justify-between">
          <router-link to="/terms_conditions"
            >Términos y Condiciones</router-link
          >
          <router-link to="/privacy_policy">Política de Privacidad</router-link>
          <router-link to="/cookies_policy">Políticas de Cookies</router-link>
        </div>

        <div class="flex flex-col gap-4 font-light justify-between">
          <router-link
            :to="{ name: 'TermsConditions', hash: '#cancellation-policy' }"
            >Políticas de Cancelación</router-link
          >
          <router-link :to="{ name: 'TermsConditions', hash: '#refund-policy' }"
            >Políticas de Reembolso</router-link
          >
          <div class="flex gap-1">
            <img class="w-10" src="../assets/visa.svg" alt="" />
            <img class="w-10" src="../assets/mastercard.svg" alt="" />
            <img class="w-10" src="../assets/discover.svg" alt="" />
            <img class="w-10" src="../assets/amex.svg" alt="" />
          </div>
        </div>
      </div>
      <a class="absolute right-0 mr-24 mt-2" href="#site-top">
        <img class="w-12" src="../assets/up-arrow.svg" alt="" />
      </a>
    </div>

    <div class="">
      <p class="sub-footer">© 2022 Botcity. Todos los derechos reservados.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>