import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

const routes = [
  { path: '/', component: () => import("../pages/LandingPage.vue") },
  { path: '/cookies_policy', name: "Cookies", component: () => import("../pages/CookiesPolicy.vue") },
  { path: '/privacy_policy', name: "Privacy", component: () => import("../pages/PrivacyPolicy.vue") },
  { path: '/terms_conditions', name: "TermsConditions", component: () => import("../pages/TermsConditions.vue") },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function (to, from, savedPosition) {
    [from, savedPosition]
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes, // short for `routes: routes`
})

export default router
